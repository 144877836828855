import {API_BASE_URL} from '../config';
import {axiosGet, axiosPost, axiosPut, axiosDelete} from '../axios';

export const DEDUCTION_TYPE_SUCCESS = 'DEDUCTION_TYPE_SUCCESS';
export const fetchSuccess = data => ({
    type: DEDUCTION_TYPE_SUCCESS,
    data
});

export const DEDUCTION_TYPE_ERROR = 'DEDUCTION_TYPE_ERROR';
export const fetchError = error => ({
    type: DEDUCTION_TYPE_ERROR,
    error
});

export const postDeductionType = (deduction_type) => (dispatch, getState) => {
    return axiosPost(`${API_BASE_URL}/deduction_types`, deduction_type)
        .then(({deduction_type}) => dispatch(fetchSuccess(deduction_type)));
};

export const getDeductionTypes = () => (dispatch, getState) => {
    return axiosGet(`${API_BASE_URL}/deduction_types/`)
        .then(({deduction_types}) => dispatch(fetchSuccess(deduction_types)));
};

export const getDeductionType = (id_deduction_type) => (dispatch, getState) => {
    return axiosGet(`${API_BASE_URL}/deduction_types/${id_deduction_type}`)
        .then(({deduction_type}) => dispatch(fetchSuccess(deduction_type)));
};

export const putDeductionType = (deduction_type) => (dispatch, getState) => {
    return axiosPut(`${API_BASE_URL}/deduction_types/${deduction_type.id_deduction_type}`, deduction_type)
        .then(({deduction_type}) => dispatch(fetchSuccess(deduction_type)));
};

export const deleteDeductionType = (id_deduction_type) => (dispatch, getState) => {
    return axiosDelete(`${API_BASE_URL}/deduction_types/${id_deduction_type}`)
        .then(({deduction_type}) => dispatch(fetchSuccess(deduction_type)));
};