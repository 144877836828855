import { API_BASE_URL } from '../config';
import { saveToken } from '../local-storage';
import axios from 'axios';
import { axiosGet, axiosPost } from '../axios';
import { ACTIONS } from './_actions_types';
import { displayErrorToast } from "./toast";

export const setToken = token => ({
    type: ACTIONS.AUTH.SET_TOKEN,
    token
});

export const clearAuth = () => ({
    type: ACTIONS.AUTH.CLEAR_AUTH
});

export const authRequest = () => ({
    type: ACTIONS.AUTH.AUTH_REQUEST
});

export const publicAuthRequest = () => ({
    type: ACTIONS.AUTH.PUBLIC_AUTH_REQUEST
});

export const authSuccess = (token, app_user) => ({
    type: ACTIONS.AUTH.AUTH_SUCCESS,
    app_user,
    token
});

export const publicAuthSuccess = () => ({
    type: ACTIONS.AUTH.PUBLIC_AUTH_SUCCESS,
    app_user: 'PUBLIC'
});

export const authError = (error) => ({
    type: ACTIONS.AUTH.AUTH_ERROR,
    error
});

// Stores the auth token in state and localStorage
// Stores the user data stored with the token
const storeAuthInfo = (token, app_user, dispatch) => {
    const id_company = app_user.id_company || app_user.companies.length > 0 ? app_user.companies[0].id_company : null;
    saveToken(token, app_user.id_user, id_company);
    app_user.companies.map((company) => {
        const stored_id_company = localStorage.getItem("company")
        if (company.id_company == stored_id_company) {
            localStorage.setItem("role", company.has_company.id_role);
        }
    })

    dispatch(authSuccess(token, app_user));
    // getLoggedUserRoles(app_user, dispatch)
};

const storeRolesInfo = (roles, dispatch) => {
    // dispatch(setRoleValue(maxRole));
}

const getLoggedUserRoles = (app_user, dispatch) => {
    axiosGet(`${API_BASE_URL}/users/${app_user.id_user}/roles`)
        .then((res) => {
            let roles = [];
            roles.push(res.data);
            storeRolesInfo(roles, dispatch)
        }, (error) => {
            displayErrorToast(error.response.data.message);
            dispatch(authError(error.response.data.message))
        })
}

export const login = (email, password, remember) => (dispatch) => {
    axios.post(`${API_BASE_URL}/users/login`, {
        email: email,
        password: password
    }).then((res) => {
        if (!localStorage.getItem('company')) {
            if (res.data.app_user.companies.length > 0) {
                localStorage.setItem('company', res.data.app_user.companies[0].id_company);
            } else {
                return alert("Vous n'êtes dans aucune entreprise.");
            }
            localStorage.setItem('user_id', res.data.app_user.id_user);
        }

        if (remember) {
            localStorage.setItem('remember', 'true');
            localStorage.setItem('email', email);
            localStorage.setItem('password', password);
        } else {
            localStorage.removeItem('remember');
            localStorage.removeItem('email');
            localStorage.removeItem('password');
        }

        storeAuthInfo(res.data.token, res.data.app_user, dispatch)

    }, (error) => {
        dispatch(authError(error))
    });

    // dispatch(authRequest());
    // return (
    //     fetch(`${API_BASE_URL}/users/login`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({
    //             email,
    //             password
    //         })
    //     })
    //         // Reject any requests which don't return a 200 status, creating
    //         // errors which follow a consistent format
    //         .then(res => normalizeResponseErrors(res))
    //         .then(res => res.json())
    //         .then(({token, app_user}) => storeAuthInfo(token, app_user, dispatch))
    //         .catch(err => {
    //             dispatch(authError(err));
    //         })
    // );
};

export const publicLogin = (token) => dispatch => {
    dispatch(publicAuthRequest());
    axiosPost(`${API_BASE_URL}/tokens/auth`, {
        token: token
    }).then((res) => {
        if (res.status === 200) {
            saveToken("Public " + token, "PUBLIC");
            dispatch(publicAuthSuccess());
        }
    }, error => {
        return;
        dispatch(authError(error))
    });
}

export const refreshToken = (id_company) => (dispatch) => {
    dispatch(authRequest());
    axiosPost(`${API_BASE_URL}/users/refresh`, { id_company })
        .then((res) => {
            storeAuthInfo(res.data.token, res.data.app_user, dispatch)
        }, error => {
            dispatch(authError(error))
        });
    //
    // dispatch(authRequest());
    // const token = getState().auth.token;
    // return fetch(`${API_BASE_URL}/users/refresh`, {
    //     method: 'POST',
    //     headers: {
    //         // Provide our existing token as credentials to get a new one
    //         Authorization: token
    //     }
    // })
    //     .then(res => normalizeResponseErrors(res))
    //     .then(res => res.json())
    //     .then(({token, app_user}) => storeAuthInfo(token, app_user, dispatch))
    //     .catch(err => {
    //         // We couldn't get a refresh token because our current credentials
    //         // are invalid or expired, or something else went wrong, so clear
    //         // them and sign us out
    //         dispatch(authError(err));
    //         dispatch(clearAuth());
    //         clearToken(token);
    //     });
};