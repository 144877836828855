import {API_BASE_URL} from '../config';
import {axiosGet, axiosPost, axiosPut, axiosDelete} from '../axios';

export const SORT1_SUCCESS = 'SORT1_SUCCESS';
export const fetchSuccess = data => ({
    type: SORT1_SUCCESS,
    data
});

export const SORT1_ERROR = 'SORT1_ERROR';
export const fetchError = error => ({
    type: SORT1_ERROR,
    error
});

export const postSort1 = (sort1) => (dispatch, getState) => {
    return axiosPost(`${API_BASE_URL}/sort1s`, sort1)
    .then(({sort1}) => dispatch(fetchSuccess(sort1)));
};

export const getSort1s = () => (dispatch, getState) => {
    return axiosGet(`${API_BASE_URL}/sort1s/`)
        .then(({sort1s}) => dispatch(fetchSuccess(sort1s)));
};

export const getSort1 = (id_sort1) => (dispatch, getState) => {
    return axiosGet(`${API_BASE_URL}/sort1s/${id_sort1}`)
        .then(({sort1}) => dispatch(fetchSuccess(sort1)));
};

export const putSort1 = (sort1) => (dispatch, getState) => {
    return axiosPut(`${API_BASE_URL}/sort1s/${sort1.id_sort1}`, sort1)
        .then(({sort1}) => dispatch(fetchSuccess(sort1)));
};

export const deleteSort1 = (id_sort1) => (dispatch, getState) => {
    return axiosDelete(`${API_BASE_URL}/sort1s/${id_sort1}`)
        .then(({sort1}) => dispatch(fetchSuccess(sort1)));
};