import {API_BASE_URL} from '../config';
import {axiosGet, axiosPost, axiosPut, axiosDelete} from '../axios';

export const CERTIFICATE_SUCCESS = 'CERTIFICATE_SUCCESS';
export const fetchSuccess = data => ({
    type: CERTIFICATE_SUCCESS,
    data
});

export const CERTIFICATE_ERROR = 'CERTIFICATE_ERROR';
export const fetchError = error => ({
    type: CERTIFICATE_ERROR,
    error
});

export const postCertificate = (certificate) => (dispatch, getState) => {
    return axiosPost(`${API_BASE_URL}/certificates`, certificate)
        .then(({certificate}) => dispatch(fetchSuccess(certificate)));
};

export const getCertificates = () => (dispatch, getState) => {
    return axiosGet(`${API_BASE_URL}/certificates`)
        .then(({certificates}) => dispatch(fetchSuccess(certificates)));
};

export const getCertificate = (id_certificate) => (dispatch, getState) => {
    return axiosGet(`${API_BASE_URL}/certificates/${id_certificate}`)
        .then(({certificate}) => dispatch(fetchSuccess(certificate)));
};

export const putCertificate = (certificate) => (dispatch, getState) => {
    return axiosPut(`${API_BASE_URL}/certificates/${certificate.id_certificate}`, certificate)
        .then(({certificate}) => dispatch(fetchSuccess(certificate)));
};

export const deleteCertificate = (id_certificate) => (dispatch, getState) => {
    return axiosDelete(`${API_BASE_URL}/certificates/${id_certificate}`)
        .then(({certificate}) => dispatch(fetchSuccess(certificate)));
};