import {API_BASE_URL} from '../config';
import {axiosGet, axiosPut, axiosPost, axiosDelete} from "../axios";
import {ACTIONS} from "./_actions_types";
import {displaySuccessToast} from "./toast";

export const MEASUREMENT_STEM_SUCCESS = 'MEASUREMENT_STEM_SUCCESS';
export const fetchSuccess = data => ({
    type: MEASUREMENT_STEM_SUCCESS,
    data
});

export const MEASUREMENT_STEM_ERROR = 'MEASUREMENT_STEM_ERROR';
export const fetchError = error => ({
    type: MEASUREMENT_STEM_ERROR,
    error
});

export const getMeasurementStems = () => (dispatch, getState) => {
    return axiosGet(`${API_BASE_URL}/measurement_stems/`)
        .then(({measurement_stems}) => dispatch(fetchSuccess(measurement_stems)));
};

export const getMeasurementStemsLots = () => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/measurement_stems_lots/${localStorage.getItem("company")}`).then((res)=>{
        dispatch({
            type:ACTIONS.LOT.MEASUREMENTS,
            payload:res.data.measurement_stems
        });
        return res.data.measurement_stems
    });
};

export const getMeasurementStemsLot = (id_lot) => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/measurement_stems_lot/${id_lot}`).then((res)=>{
        dispatch({
            type:ACTIONS.LOT.LOT_MEASUREMENTS,
            payload:res.data.measurement_stems
        });
        return res.data.measurement_stems
    });
};

export const getMeasurementStem = (id_measurement_stem) => (dispatch, getState) => {
    return axiosGet(`${API_BASE_URL}/measurement_stems/${id_measurement_stem}`)
        .then(({measurement_stem}) => dispatch(fetchSuccess(measurement_stem)));
};

export const putMeasurementStem = (measurement_stem, display_toast = true) => (dispatch, getState) => {
    return axiosPut(`${API_BASE_URL}/measurement_stems/${measurement_stem.id_measurement_stem}`, measurement_stem)
        .then((measurement_stem) => {
            if (measurement_stem.error !== undefined) {
                alert(measurement_stem.error + " stem_number not unique") //pile_number not unique
                fetchError(measurement_stem)
            }
            dispatch(fetchSuccess(measurement_stem))
            if (display_toast) displaySuccessToast('Tige modifié avec succès');
            return measurement_stem
        });
};

export const deleteMeasurementStem = (id_measurement_stem) => (dispatch, getState) => {
    return axiosDelete(`${API_BASE_URL}/measurement_stems/${id_measurement_stem}`).then(() => {
        displaySuccessToast('Tige supprimée avec succès');
    })
        // .then(({measurement_stem}) => dispatch(fetchSuccess(measurement_stem)));
};

export const postMeasurementStemDeduction = (diameter, id_essence) => (dispatch) => {
    return axiosPost(`${API_BASE_URL}/measurement_stem/percent_deduction`, {diameter: diameter, id_essence: id_essence}).then((res)=>{
        dispatch({
            type:ACTIONS.MEASUREMENT_STEMS.GET_DEDUCTION_BARK,
            payload:res.data
        });
        return res.data
    }, (error)=>{
        dispatch({
            type:ACTIONS.ERROR,
            payload:error.response
        })
    });
};

export const getMeasurementStemsMap = () => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/measurement_stems_map/`).then((res) => {
        dispatch({
            type: ACTIONS.MEASUREMENT_STEMS.MAP_STEMS,
            payload: res.data.measurement_stems
        });
        return res.data.measurement_stems
    }, (error) => {
        dispatch({
            type: ACTIONS.ERROR,
            payload: error.response
        })
    });
};


export const getPublicMeasurementStemsLot = (id_lot, token) => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/measurement_stems_lot/${id_lot}/${token}`).then((res)=>{
        dispatch({
            type:ACTIONS.LOT.LOT_MEASUREMENTS,
            payload:res.data.measurement_stems
        });
        return res.data.measurement_stems
    });
};