import { ACTIONS } from '../actions/_actions_types';

const initialState = {
    token: null, // authToken !== null does not mean it has been validated
    app_user: null,
    loading: false,
    error: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.AUTH.SET_TOKEN:
            return {
                ...state,
                token: action.token
            };
        case ACTIONS.AUTH.CLEAR_AUTH:
            return {
                ...state,
                token: null,
                app_user: null
            };
        case ACTIONS.AUTH.AUTH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case ACTIONS.AUTH.AUTH_SUCCESS:
            return {
                ...state,
                loading: false,
                app_user: action.app_user,
                token: action.token,
            };
        case ACTIONS.AUTH.AUTH_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
                app_user: null,
            };
        case ACTIONS.AUTH.PUBLIC_AUTH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case ACTIONS.AUTH.PUBLIC_AUTH_SUCCESS:
            return {
                ...state,
                loading: false,
                app_user: 'PUBLIC'
            };
    }
    return state;
}