import { axiosGet, axiosPut, axiosDelete } from '../axios';
import {API_BASE_URL} from '../config';

export const DEPOSIT_SUCCESS = 'DEPOSIT_SUCCESS';
export const fetchSuccess = data => ({
    type: DEPOSIT_SUCCESS,
    data
});

export const DEPOSIT_ERROR = 'DEPOSIT_ERROR';
export const fetchError = error => ({
    type: DEPOSIT_ERROR,
    error
});

export const getDeposits = () => (dispatch, getState) => {
    return axiosGet(`${API_BASE_URL}/deposits/`)
        .then(({deposits}) => dispatch(fetchSuccess(deposits)));
};

export const getDeposit = (id_deposit) => (dispatch, getState) => {
    return axiosGet(`${API_BASE_URL}/deposits/${id_deposit}`)
        .then(({deposit}) => dispatch(fetchSuccess(deposit)));
};

export const putDeposit = (deposit) => (dispatch, getState) => {
    return axiosPut(`${API_BASE_URL}/deposits/${deposit.id_deposit}`, deposit)
        .then(({deposit}) => dispatch(fetchSuccess(deposit)));
};

export const deleteDeposit = (id_deposit) => (dispatch, getState) => {
    return axiosDelete(`${API_BASE_URL}/deposits/${id_deposit}`)
        .then(({deposit}) => dispatch(fetchSuccess(deposit)));
};