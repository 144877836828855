import {API_BASE_URL} from '../config';
import {axiosGet, axiosPost, axiosPut, axiosDelete} from '../axios';

export const ESSENCE_SUCCESS = 'ESSENCE_SUCCESS';
export const fetchSuccess = data => ({
    type: ESSENCE_SUCCESS,
    data
});

export const ESSENCE_ERROR = 'ESSENCE_ERROR';
export const fetchError = error => ({
    type: ESSENCE_ERROR,
    error
});

export const postEssence = (essence) => (dispatch, getState) => {
    return axiosPost(`${API_BASE_URL}/essences`, essence)
    .then(({essence}) => dispatch(fetchSuccess(essence)));
};

export const getEssences = () => (dispatch, getState) => {
    return axiosGet(`${API_BASE_URL}/essences/`)
        .then(({essences}) => dispatch(fetchSuccess(essences)));
};

export const getEssence = (id_essence) => (dispatch, getState) => {
    return axiosGet(`${API_BASE_URL}/essences/${id_essence}`)
        .then(({essence}) => dispatch(fetchSuccess(essence)));
};

export const putEssence = (essence) => (dispatch, getState) => {
    return axiosPut(`${API_BASE_URL}/essences/${essence.id_essence}`, essence)
        .then(({essence}) => dispatch(fetchSuccess(essence)));
};

export const deleteEssence = (id_essence) => (dispatch, getState) => {
    return axiosDelete(`${API_BASE_URL}/essences/${id_essence}`)
        .then(({essence}) => dispatch(fetchSuccess(essence)));
};

// Category
export const getCategories = (id_essence) => (dispatch, getState) => {
    return axiosGet(`${API_BASE_URL}/essences/${id_essence}/categories`)
        .then(({categories}) => dispatch(fetchSuccess(categories)));
};

export const postCategory = (id_essence, category) => (dispatch, getState) => {
    return axiosPost(`${API_BASE_URL}/essences/${id_essence}/categories`, category)
        .then(({category}) => dispatch(fetchSuccess(category)));
};