import { setHeaders } from "./axios";

export const loadToken = () => {
    return localStorage.getItem('token');
};

export const saveToken = (token, id_user, id_company) => {
    if (!token) throw new Error('No token provided');
    try {
        setHeaders(token, id_company);
        localStorage.setItem('token', token);
        localStorage.setItem('user_id', id_user);
    } catch (e) {
        alert(e);
    }
};

export const clearToken = () => {
    try {
        localStorage.removeItem('company');
        localStorage.removeItem('user_id');
        localStorage.removeItem('token');
    } catch (e) {}
};