import {API_BASE_URL} from '../config';
import {axiosGet, axiosPost, axiosPut, axiosDelete} from '../axios';
import {ACTIONS} from "./_actions_types";
import {displayErrorToast, displaySuccessToast} from "./toast";

export const getCurrentMeasurements = () => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/measurements/${localStorage.getItem('company')}`).then((res)=>{
        dispatch({
            type:ACTIONS.MEASUREMENTS.GET_ALL,
            payload:res.data
        })
    });
};

export const getMeasurements = (id_company) => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/measurements/${id_company}`).then((res)=>{
        dispatch({
            type:ACTIONS.MEASUREMENTS.GET_ALL,
            payload:res.data
        })
    });
};

export const getMeasurement = (id) => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/measurement/${id}`).then((res)=>{
        dispatch({
            type:ACTIONS.MEASUREMENTS.GET,
            payload:res.data
        })
        return res.data.measurement
    });
};

export const postMeasurement = (measurement) => (dispatch) => {
    return axiosPost(`${API_BASE_URL}/measurement`, measurement).then((res)=>{
        dispatch({
            type:ACTIONS.MEASUREMENTS.POST,
            payload:res.data
        })
        displaySuccessToast('Cubage créer avec succès');
        return res.data
    }, (error)=>{
        dispatch({
            type:ACTIONS.ERROR,
            payload:error.response
        })
        displayErrorToast(`Erreur lors de la création du cubage`);
    });
};

export const searchMeasurement = (data) => (dispatch) => {
    dispatch({
        type:ACTIONS.MEASUREMENTS.SEARCH,
        payload:{data}
    })
};

export const deleteMeasurement = (id) => (dispatch) => {
    axiosDelete(`${API_BASE_URL}/measurement/`+id).then((res)=>{
        dispatch({
            type:ACTIONS.MEASUREMENTS.DELETE,
            payload:{id:id,measurement:res.data}
        })
        displaySuccessToast('Cubage supprimé avec succès');
    }, (error)=>{
        dispatch({
            type:ACTIONS.ERROR,
            payload:error.response
        })
        displayErrorToast(`Erreur lors de la suppression du cubage`);
    });
};

export const putMeasurement = (measurement) => (dispatch) => {

    axiosPut(`${API_BASE_URL}/measurement/${measurement.id_measurement}`, measurement).then((res)=>{
        dispatch(getMeasurements(localStorage.getItem('company')))
        displaySuccessToast('Cubage modifié avec succès');
    }, (error)=>{
        dispatch({
            type:ACTIONS.ERROR,
            payload:error.response
        })
        displayErrorToast(`Erreur lors de la modification du cubage`);
    });
};

// // MeasurementStem
export const MEASUREMENT_SUCCESS = 'MEASUREMENT_SUCCESS';
export const fetchSuccess = data => ({
    type: MEASUREMENT_SUCCESS,
    data
});

export const MEASUREMENT_ERROR = 'MEASUREMENT_ERROR';
export const fetchError = error => ({
    type: MEASUREMENT_ERROR,
    error
});

export const getMeasurementLastNumber = (id_measurement) => (dispatch) => {
    return axiosPost(`${API_BASE_URL}/measurement/last_number`, {id_measurement: id_measurement}).then((res)=>{
        dispatch({
            type:ACTIONS.MEASUREMENTS.GET_LAST_NUMBER,
            payload:res.data.lastNumber
        })
        return res.data.lastNumber
    });
};

export const getMeasurementStems = (id_measurement) => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/measurements/${id_measurement}/measurement_stems`).then((res)=>{
        dispatch({
            type:ACTIONS.MEASUREMENTS.GET_ALL_STEMS,
            payload:res.data.measurement_stems
        })
    });
};

export const getMeasurementPiles = (id_measurement) => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/measurements/${id_measurement}/measurement_piles`).then((res)=>{
        dispatch({
            type:ACTIONS.MEASUREMENTS.GET_ALL_PILES,
            payload:res.data.measurement_piles
        })
    });
};

// export const getMeasurementStems = (id_measurement) => (dispatch, getState) => {
//     return axiosGet(`${API_BASE_URL}/measurements/${id_measurement}/measurement_stems`)
//         .then(({measurement_stems}) => dispatch(fetchSuccess(measurement_stems)));
// };

export const postMeasurementStem = (id_measurement, measurement_stem) => (dispatch, getState) => {
    return axiosPost(`${API_BASE_URL}/measurements/${id_measurement}/measurement_stems`, measurement_stem)
        .then((measurement_stem) => {
            if (measurement_stem === undefined) {
                alert("stem_number not unique") //stem_number not unique
                fetchError(measurement_stem)
            }
            dispatch(fetchSuccess(measurement_stem))
            dispatch(getMeasurementStems(id_measurement))
            return measurement_stem
        });
};

// MeasurementPile
// export const getMeasurementPiles = (id_measurement) => (dispatch, getState) => {
//     return axiosGet(`${API_BASE_URL}/measurements/${id_measurement}/measurement_piles`)
//         .then(({measurement_piles}) => dispatch(fetchSuccess(measurement_piles)));
// };

export const postMeasurementPile = (id_measurement, measurement_pile) => (dispatch, getState) => {
    return axiosPost(`${API_BASE_URL}/measurements/${id_measurement}/measurement_piles`, measurement_pile)
        .then((measurement_pile) => {
            if (measurement_pile === undefined) {
                alert("pile_number not unique") //pile_number not unique
                fetchError(measurement_pile)
            }
            dispatch(fetchSuccess(measurement_pile))
            dispatch(getMeasurementPiles(id_measurement))
            displaySuccessToast('Pile créer avec succès');
            return measurement_pile
        }, (error) => {
            displayErrorToast(`Erreur lors de la création de la pile`);
            alert("error" + error)
        });
};

//volume
export const getMeasurementVolume = (json_data) => (dispatch) => {
    return axiosPost(`${API_BASE_URL}/volume`, json_data).then((res)=>{
        dispatch({
            type:ACTIONS.MEASUREMENTS.GET_VOLUME,
            payload:res.data.data
        })
    });
};
