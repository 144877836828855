import {API_BASE_URL} from '../config';
import {axiosGet, axiosPost, axiosPut, axiosDelete} from '../axios';

export const SORT2_SUCCESS = 'SORT2_SUCCESS';
export const fetchSuccess = data => ({
    type: SORT2_SUCCESS,
    data
});

export const SORT2_ERROR = 'SORT2_ERROR';
export const fetchError = error => ({
    type: SORT2_ERROR,
    error
});

export const postSort2 = (sort2) => (dispatch, getState) => {
    return axiosPost(`${API_BASE_URL}/sort2s`, sort2)
    .then(({sort2}) => dispatch(fetchSuccess(sort2)));
};

export const getSort2s = () => (dispatch, getState) => {
    return axiosGet(`${API_BASE_URL}/sort2s/`)
        .then(({sort2s}) => dispatch(fetchSuccess(sort2s)));
};

export const getSort2 = (id_sort2) => (dispatch, getState) => {
    return axiosGet(`${API_BASE_URL}/sort2s/${id_sort2}`)
        .then(({sort2}) => dispatch(fetchSuccess(sort2)));
};

export const putSort2 = (sort2) => (dispatch, getState) => {
    return axiosPut(`${API_BASE_URL}/sort2s/${sort2.id_sort2}`, sort2)
        .then(({sort2}) => dispatch(fetchSuccess(sort2)));
};

export const deleteSort2 = (id_sort2) => (dispatch, getState) => {
    return axiosDelete(`${API_BASE_URL}/sort2s/${id_sort2}`)
        .then(({sort2}) => dispatch(fetchSuccess(sort2)));
};