import {API_BASE_URL} from '../config';
import {axiosGet, axiosPost, axiosPut, axiosDelete} from '../axios';

export const ASSORTMENT_SUCCESS = 'ASSORTMENT_SUCCESS';
export const fetchSuccess = data => ({
    type: ASSORTMENT_SUCCESS,
    data
});

export const ASSORTMENT_ERROR = 'ASSORTMENT_ERROR';
export const fetchError = error => ({
    type: ASSORTMENT_ERROR,
    error
});

export const postAssortment = (assortment) => (dispatch, getState) => {
    return axiosPost(`${API_BASE_URL}/assortments`, assortment)
        .then(({assortment}) => dispatch(fetchSuccess(assortment)));
};

export const getAssortments = () => (dispatch, getState) => {
    return axiosGet(`${API_BASE_URL}/assortments/`)
        .then(({assortments}) => dispatch(fetchSuccess(assortments)));
};

export const getAssortment = (id_assortment) => (dispatch, getState) => {
    return axiosGet(`${API_BASE_URL}/assortments/${id_assortment}`)
        .then(({assortment}) => dispatch(fetchSuccess(assortment)));
};

export const putAssortment = (assortment) => (dispatch, getState) => {
    return axiosPut(`${API_BASE_URL}/assortments/${assortment.id_assortment}`, assortment)
        .then(({assortment}) => dispatch(fetchSuccess(assortment)));
};

export const deleteAssortment = (id_assortment) => (dispatch, getState) => {
    return axiosDelete(`${API_BASE_URL}/assortments/${id_assortment}`)
        .then(({assortment}) => dispatch(fetchSuccess(assortment)));
};